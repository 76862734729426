export function getKeyByValue(object, value) {
	return Object.keys(object).find(key => object[key] === value)
}

export const getNonEmpty = (field) => {
	const defaultLanguage = 'es'
	if (!field || !Object.keys(field)?.length) return '----'
	if (field[defaultLanguage] && field[defaultLanguage] !== '') return field[defaultLanguage]
	for (const lang of Object.keys(field)) {
		if (field[lang] && field[lang] !== '') return field[lang]
	}
	return '----'
}

export const checkIsEmpty = (field) => {
	if (typeof field === 'string') return field === null || field === '' || field === ' '
	return Object.values(field).every(x => x === null || x === '' || x === ' ');
}

export const delay = (n) => new Promise( r => setTimeout(r, n*1000))