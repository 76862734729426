import React, { useState, useEffect } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
} from '../../../../_metronic/_partials/controls';
import {
	Button,
	TextField,
	MuiThemeProvider,
	createMuiTheme,
	FormControlLabel,
	Checkbox
} from '@material-ui/core';
import Select from 'react-select';
import { useHistory, useParams } from 'react-router-dom';
import {
	deletePunto,
	getPuntoById,
	postPunto,
	updatePunto,
} from '../../../../api/punto';
import { useSkeleton } from '../../../hooks/useSkeleton';
import { alertError, alertSuccess } from '../../../../utils/logger';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import { checkIsEmpty } from '../../../../utils/helpers';
import { getLocalidades } from '../../../../api/localidad'
import { getProvincias } from '../../../../api/provincia'
import { getComunidades } from '../../../../api/comunidad'
import { getEntidades } from '../../../../api/entidad'

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: '#F64E60',
		},
	},
});

function getEmptyPunto() {
	return {
		fullName: '',
		address: '',
		postalCode: '',
		phone: '',
		lat: '',
		long: '',
		linkURL: '',
		localidad: '',
		entidad: '',
		active: true
	};
}

export default function EditPuntosPage() {
	const [punto, setPunto] = useState(getEmptyPunto());
	const [localidades, setLocalidades] = useState(null)
	const [comunidades, setComunidades] = useState(null)
	const [entidades, setEntidades] = useState(null)
	const [provincias, setProvincias] = useState(null)
	const [selectedLocalidad, setSelectedLocalidad] = useState(null)
	const [selectedProvincia, setSelectedProvincia] = useState(null)
	const [selectedComunidad, setSelectedComunidad] = useState(null)
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const puntoId = useParams().id;
	const history = useHistory();

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		getLocalidades().then(res => {
		  if (res.status === 200) {
			setLocalidades(res.data)
		  }
		}).catch(error => {
		  alertError({ error: error, customMessage: 'No se han podido obtener las localidades.' })
		  history.push('/puntos')
		})
		getComunidades().then(res => {
			if (res.status === 200) {
			  setComunidades(res.data)
			}
		  }).catch(error => {
			alertError({ error: error, customMessage: 'No se han podido obtener las comunidades.' })
			history.push('/puntos')
		  })
		getProvincias().then(res => {
		  if (res.status === 200) {
			setProvincias(res.data)
		  }
		}).catch(error => {
		  alertError({ error: error, customMessage: 'No se han podido obtener las provincias.' })
		  history.push('/puntos')
		})
		getEntidades().then(res => {
		  if (res.status === 200) {
			setEntidades(res.data)
		  }
		}).catch(error => {
		  alertError({ error: error, customMessage: 'No se han podido obtener las entidades.' })
		  history.push('/puntos')
		})
		if (!puntoId) {
			disableLoadingData();
			return;
		}
		getPuntoById(puntoId)
			.then((res) => {
				if (res.status === 200) {
					setPunto(res.data);
					disableLoadingData();
					setSelectedProvincia(res.data.additionalData?.provincia)
					setSelectedComunidad(res.data.additionalData?.comunidad)
				}
			})
			.catch((error) => {
				alertError({ error: error, customMessage: 'No se ha podido obtener el punto.' });
				history.push('/puntos');
			});
	}, [puntoId, disableLoadingData, history]);

	function savePunto() {
		if (checkIsEmpty(punto.fullName)) return alertError({ error: null, customMessage: 'El nombre es requerido.' })
		if (checkIsEmpty(punto.address)) return alertError({ error: null, customMessage: 'La dirección es requerida.' })
		if (checkIsEmpty(punto.postalCode)) return alertError({ error: null, customMessage: 'El código postal es requerido.' })
		if (checkIsEmpty(punto.phone)) return alertError({ error: null, customMessage: 'El teléfono es requerido.' })
		if (checkIsEmpty(punto.lat)) return alertError({ error: null, customMessage: 'La latitud es requerida.' })
		if (checkIsEmpty(punto.long)) return alertError({ error: null, customMessage: 'La longitud es requerida.' })
		if (punto.linkURL?.length) {
			try {
				if (!Boolean(new URL(punto.linkURL?.length)))
					return alertError({
						error: null,
						customMessage: 'El enlace no es válido.',
					});
			} catch (error) {
				return alertError({
					error: null,
					customMessage: 'El enlace no es válido.',
				});
			}
		}
		if (!puntoId) {
			postPunto(punto)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: '¡Guardado!',
							customMessage: 'Punto creado correctamente.',
						});
						history.push('/puntos');
					}
				})
				.catch((error) => {
					alertError({ error: error, customMessage: 'Could not save punto.' });
				});
		} else {
			updatePunto(puntoId, punto)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: '¡Guardado!',
							customMessage: 'Los cambios se han guardado correctamente.',
						});
						history.push('/puntos');
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'No se han podido guardar los cambios.',
					});
				});
		}
	}

	const handleChange = (element) => (event) => {
		if (element === 'localidad') {
		  	setPunto({ ...punto, [element]: event._id})
		} else if (element === 'entidad') {
			setPunto({ ...punto, [element]: event._id})
		}
		else setPunto({ ...punto, [element]: event.target.value })
	};

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title='Editar punto'></CardHeader>
					<CardBody>
						<TextField
							id={`fullName`}
							label="Nombre"
							value={punto.fullName}
							onChange={handleChange('fullName')}
							InputLabelProps={{
							shrink: true
							}}
							margin="normal"
							variant="outlined"
							required
						/>
						<TextField
							id={`address`}
							label="Dirección"
							value={punto.address}
							onChange={handleChange('address')}
							InputLabelProps={{
							shrink: true
							}}
							margin="normal"
							variant="outlined"
							required
						/>
												<span>Comunidad autónoma</span>
						<Select
							labelId="demo-simple-select-standard-label"
							id="demo-simple-select-standard"
							className="my-2"
							defaultValue={selectedComunidad || ''}
							value={selectedComunidad || ''}
							onChange={(event) => {
								setSelectedProvincia(null)
								setSelectedLocalidad(null)
								setPunto({ ...punto, localidad: '' })
								setSelectedComunidad(event)
							}}
							options={comunidades}
							getOptionLabel={(option) => `${option.name}`}
							getOptionValue={(option) => `${option._id}`}
							placeholder="Seleccione una comunidad..."
							styles={{
								// Fixes the overlapping problem of the component
								menu: provided => ({ ...provided, zIndex: 9999 })
							}}
						/>
						{selectedComunidad && <>
							<span>Província</span>
							<Select
								labelId="demo-simple-select-standard-label"
								id="demo-simple-select-standard"
								className="my-2"
								defaultValue={selectedProvincia || ''}
								value={selectedProvincia || ''}
								onChange={(event) => {
									setPunto({ ...punto, localidad: '' })
									setSelectedLocalidad(null)
									setSelectedProvincia(event)
								}}
								options={provincias?.filter(item => item.comunidad === selectedComunidad._id)}
								getOptionLabel={(option) => `${option.fullName}`}
								getOptionValue={(option) => `${option._id}`}
								placeholder="Seleccione una província..."
								styles={{
									// Fixes the overlapping problem of the component
									menu: provided => ({ ...provided, zIndex: 9999 })
								}}
							/>
						</>}
						{selectedProvincia && selectedComunidad && <>
							<span>Localidad</span>
							<Select
								labelId="demo-simple-select-standard-label"
								id="demo-simple-select-standard"
								className="my-2"
								defaultValue={selectedLocalidad || (punto.localidad && localidades ? localidades.find(item => item._id === punto.localidad) : '')}
								value={selectedLocalidad || (punto.localidad && localidades ? localidades.find(item => item._id === punto.localidad) : '')}
								onChange={(event) => {
									setPunto({ ...punto, localidad: event._id })
									setSelectedLocalidad(event)
								}}
								options={localidades?.filter(item => item.provincia === selectedProvincia._id)}
								getOptionLabel={(option) => `${option.fullName}`}
								getOptionValue={(option) => `${option._id}`}
								placeholder="Seleccione una localidad..."
								styles={{
									// Fixes the overlapping problem of the component
									menu: provided => ({ ...provided, zIndex: 9999 })
								}}
							/>
						</>}
						<TextField
							id={`postalCode`}
							label="Código postal"
							value={punto.postalCode}
							onChange={handleChange('postalCode')}
							InputLabelProps={{
							shrink: true
							}}
							margin="normal"
							variant="outlined"
							required
						/>
						<TextField
							id={`phone`}
							label="Teléfono"
							value={punto.phone}
							onChange={handleChange('phone')}
							InputLabelProps={{
							shrink: true
							}}
							margin="normal"
							variant="outlined"
							required
						/>
						<TextField
							id={`lat`}
							label="Latitud"
							value={punto.lat}
							onChange={handleChange('lat')}
							InputLabelProps={{
							shrink: true
							}}
							margin="normal"
							variant="outlined"
							required
						/>
						<TextField
							id={`long`}
							label="Longitud"
							value={punto.long}
							onChange={handleChange('long')}
							InputLabelProps={{
							shrink: true
							}}
							margin="normal"
							variant="outlined"
							required
						/>
						<TextField
							id={`linkURL`}
							label="Enlace"
							value={punto.linkURL}
							onChange={handleChange('linkURL')}
							InputLabelProps={{
							shrink: true
							}}
							margin="normal"
							variant="outlined"
						/>
						<span>Entidad</span>
						<Select
						  labelId="demo-simple-select-standard-label"
						  id="demo-simple-select-standard"
						  className="my-2"
						  defaultValue={punto.entidad && entidades ? entidades.find(item => item._id === punto.entidad) : ''}
						  onChange={handleChange('entidad')}
						  options={entidades}
						  getOptionLabel={(option) => `${option.name}`}
						  getOptionValue={(option) => `${option._id}`}
						  placeholder="Seleccione una entidad..."
						  styles={{
							// Fixes the overlapping problem of the component
							menu: provided => ({ ...provided, zIndex: 9999 })
						  }}
						/>
						<br/>
						<FormControlLabel
							control={<Checkbox checked={punto.active} onChange={() => setPunto({ ...punto, active: !punto.active })} name="checkActive" />}
							label="Activo"
						/>
					</CardBody>
				</Card>
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<Button
						onClick={() => history.push('/puntos')}
						variant='outlined'
						style={{ marginRight: '20px' }}>
						Atrás
					</Button>
					<Button
						onClick={() => savePunto()}
						variant='outlined'
						color='primary'
						style={{ marginRight: '20px' }}>
						Guardar punto
					</Button>
					{puntoId && (
						<>
							<MuiThemeProvider theme={theme}>
								<Button
									onClick={() => setOpenConfirmDialog(true)}
									variant='outlined'
									color='secondary'
									style={{ marginRight: '20px' }}>
									Eliminar punto
								</Button>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										marginLeft: 'auto',
									}}></div>
							</MuiThemeProvider>

							<ConfirmDialog
								title={'¿Estás seguro de que quieres eliminar este punto?'}
								open={openConfirmDialog}
								setOpen={setOpenConfirmDialog}
								onConfirm={() => {
									deletePunto(puntoId)
										.then((res) => {
											if (res.status === 204 || res.status === 200) {
												alertSuccess({
													title: '¡Eliminado!',
													customMessage: 'Punto eliminado correctamente',
												});
												history.push('/puntos');
											}
										})
										.catch((error) => {
											alertError({
												error: error,
												customMessage: 'No se ha podido eliminar el punto.',
											});
										});
								}}
							/>
						</>
					)}
				</div>
			</>
		);
}
