import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader } from '../../../../_metronic/_partials/controls'
import {
	Button,
	TextField
} from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import { getTextById, updateText } from '../../../../api/text'
import { useSkeleton } from '../../../hooks/useSkeleton'
import { alertError, alertSuccess } from '../../../../utils/logger'
import Editor from '../../../components/editor/Editor'


function getEmptyText() {
  return {
    title: '',
    content: ''
  }
}

export default function EditTextsPage() {
  const [text, setText] = useState(getEmptyText())
  const textId = useParams().id
  const history = useHistory()

  const { isLoading: isLoadingData, disableLoading: disableLoadingData, ContentSkeleton } = useSkeleton()

  useEffect(() => {
    if (!textId) {
      disableLoadingData()
      alertError({ error: null, customMessage: 'No se ha podido obtener el texto.' })
      return history.push('/texts')
    }
    getTextById(textId).then(res => {
      if (res.status === 200) {
        setText(res.data)
        disableLoadingData()
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'No se ha podido obtener el texto.' })
      history.push('/texts')
    })
  }, [textId, disableLoadingData, history])

  function saveText() {
    updateText(textId, text).then(res => {
        if (res.status === 200) {
          alertSuccess({ title: '¡Guardado!', customMessage: 'Cambios guardados correctamente.' })
          history.push('/texts')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'No se han podido guardar los cambios.' })
      })
  }

  const handleChange = (element) => (event) => {
    if (event.target.value === ' ') return
    setText({ ...text, [element]: event.target.value })
  }

  const handleChangeEditor = (element, value) => {
    if (value === ' ') return
    setText({ ...text, [element]: value })
  }

  if (isLoadingData)
    return <ContentSkeleton />
  else return (
    <>
      <Card>
        <CardHeader title='Editar texto legal'>
        </CardHeader>
        <CardBody>
          <TextField
            id={`title`}
            label="Título"
            value={text.title || ''}
            onChange={handleChange('title')}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            required
          />
          <Editor
            body={text.content|| ''}
            setBody={new_body => handleChangeEditor('content', new_body)}
            className='max-height'
            placeholder={'Ingrese el contenido aquí...'}
          />
        </CardBody>
      </Card>
      <Button
        onClick={() => history.push('/texts')}
        variant="outlined"
        style={{ marginRight: '20px' }}>
        Atrás
      </Button>
      <Button
        onClick={() => saveText()}
        variant="outlined"
        color="primary"
        style={{ marginRight: '20px' }}>
        Guardar texto
      </Button>
    </>
  );
}
