import React, { useEffect, useState } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from '../../../../_metronic/_partials/controls';
import Table, {
	//dateFormatter,
	buttonsStyle
} from '../../../components/tables/table';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import { getPuntos, deletePunto, changeStatusPunto } from '../../../../api/punto';
import { Button, Tooltip, FormControl, InputLabel, Select, MenuItem, TextField } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { alertError, alertSuccess } from '../../../../utils/logger';
import { useHistory } from 'react-router-dom';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import { shallowEqual, useSelector } from 'react-redux';
import { getLocalidades } from '../../../../api/localidad'
import { getEntidades } from '../../../../api/entidad'
import { getComunidades } from '../../../../api/comunidad'
import { getProvincias } from '../../../../api/provincia'
import FiltersCard from '../../../components/filters/Filter';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: () => null,
};

function getData(puntos, localidades, entidades, comunidades, provincias) {
	let data = [];
	for (let i = 0; i < puntos.length; ++i) {
		const localidad = puntos[i].localidad ? localidades.find(cat => cat._id === puntos[i].localidad) : null
		const provincia = localidad ? provincias.find(cat => cat._id === localidad.provincia) : null
		const comunidad = provincia ? comunidades.find(cat => cat._id === provincia.comunidad) : null

		const elem = {};
		elem.name = puntos[i].fullName;
		elem.comunidad = comunidad ? comunidad.name : '---'
		elem.provincia = provincia ? provincia.fullName : '---'
		elem.localidad = localidad ? localidad.fullName : '---'
		elem.entidad = puntos[i].entidad ? entidades.find(cat => cat._id === puntos[i].entidad)?.name : '---'
		elem.postalCode = puntos[i].postalCode;
		elem.address = puntos[i].address;
		//elem.createdAt = puntos[i].createdAt;
		elem.id = puntos[i]._id;
		elem.active = puntos[i].active;
		data = data.concat(elem);
	}
	return data;
}

const initialFilters = {
	comunidad: [],
  	provincia: [],
	localidad: [],
	cp: null
}

export default function PuntosPage() {
	const [data, setData] = useState([]);
	const [puntoId, setPuntoId] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
	const [refresh, setRefresh] = useState(false);
	const [filteredData, setFilteredData] = useState([]);
	const [comunidades, setComunidades] = useState([])
  	const [provincias, setProvincias] = useState([])
	const [localidades, setLocalidades] = useState([])
	const [entidades, setEntidades] = useState([])
  	const [filterOptions, setFilterOptions] = useState(initialFilters)
	const [collapsed, setCollapsed] = useState(true)
	const history = useHistory();
	const user = useSelector((store) => store.authentication?.user, shallowEqual)

	function buttonFormatter(cell) {
		const elem = data.find(item => item._id === cell)
		return (
			<>
				<Tooltip title="Editar">
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => history.push('/edit-punto/' + cell)}>
						<EditIcon />
					</Button>
				</Tooltip>
				{user?.role === 'admin' && <>
					<Tooltip title={elem?.active ? "Desactivar" : "Activar"}>
						<Button
							style={buttonsStyle} size="small"
							onClick={() => {
								setPuntoId(elem)
								setOpenConfirmDialog(1)
							}}>
							{elem?.active ? <ToggleOffIcon/> : <ToggleOnIcon style={{color :'red'}}/>}
						</Button>
					</Tooltip>
					<Tooltip title="Eliminar">
						<Button
							style={buttonsStyle}
							size='small'
							onClick={() => {
								setPuntoId(cell);
								setOpenConfirmDialog(2);
							}}>
							<DeleteIcon />
						</Button>
					</Tooltip>
				</>}
			</>
		);
	}

	const columns = [
		{ dataField: 'name', text: 'Nombre', sort: true },
		{ dataField: 'comunidad', text: 'Comunidad', sort: true },
		{ dataField: 'provincia', text: 'Província', sort: true },
		{ dataField: 'localidad', text: 'Localidad', sort: true },
		{ dataField: 'postalCode', text: 'Código postal', sort: true },
		{ dataField: 'address', text: 'Dirección', sort: true },
		{ dataField: 'entidad', text: 'Entidad', sort: true },
		/*{
			dataField: 'createdAt',
			text: 'Fecha de creación',
			formatter: dateFormatter,
			sort: true,
		},*/
		{ dataField: 'id', text: '', formatter: buttonFormatter },
	];

	useEffect(() => {
		getEntidades().then((respE) => {
			if (respE.status === 200) {
				setEntidades(respE.data)
				setRefresh(false)
			}
		  }).catch(error => {
			alertError({ error: error, customMessage: 'No se han podido obtener las entidades.' })
		  })
		getLocalidades().then((resp) => {
			if (resp.status === 200) {
				setLocalidades(resp.data)
				setRefresh(false)
			}
		  }).catch(error => {
			alertError({ error: error, customMessage: 'No se han podido obtener las localidades.' })
		  })
		getProvincias().then(respP => {
			if (respP.status === 200) {
				setProvincias(respP.data)
				setRefresh(false)
			}
		}).catch(error => {
			alertError({ error: error, customMessage: 'No se han podido obtener las provincias.' })
		})
		getComunidades().then(respC => {
			if (respC.status === 200) {
				setComunidades(respC.data)
				setRefresh(false)
			}
		}).catch(error => {
			alertError({ error: error, customMessage: 'No se han podido obtener las comunidades.' })
		})
		getPuntos().then((res) => {
			if (res.status === 200) {
				setData(res.data)
				setFilteredData(res.data)
				setRefresh(false)
			}
		})
		.catch((error) => {
			alertError({ error: error, customMessage: 'No se han podido obtener los puntos.' });
		});
	}, [refresh]);

	const handleSearch = async () => {
		if (!data.length) return
		setFilteredData(data.filter(item => {
		  	let filter = true
			if (filterOptions.comunidad && filterOptions.comunidad.length) filter = filter && filterOptions.comunidad.includes(provincias.find(c => c._id === (localidades.find(c => c._id === item.localidad)?.provincia))?.comunidad)
			if (filterOptions.provincia && filterOptions.provincia.length) filter = filter && filterOptions.provincia.includes(localidades.find(c => c._id === item.localidad)?.provincia)
			if (filterOptions.localidad && filterOptions.localidad.length) filter = filter && filterOptions.localidad.includes(item.localidad)
			if (filterOptions.cp && filterOptions.cp.length) filter = filter && item.postalCode.includes(filterOptions.cp)
			if (filter) return item
			return false
		}))
	}

	const handleClearFilters = () => {
		setFilterOptions(initialFilters)
		setRefresh(true)
	}

	const handleChange = (element) => (event) => {
		setFilterOptions({ ...filterOptions, [element]: event.target.value });
	}; 

	const renderFiltersContent = () => {
		return <>
			<br/>
			<FormControl style={{ width: '100%' }}>
				<InputLabel id="demo-simple-select-standard-label">Comunidad</InputLabel>
				<Select
					labelId="demo-simple-select-standard-label"
					id="demo-simple-select-standard"
					value={filterOptions.comunidad || ''}
					multiple
					onChange={handleChange('comunidad')}
					MenuProps={MenuProps}
				>
				{comunidades?.map((option) => (
					<MenuItem key={option._id} value={option._id}>{option.name}</MenuItem>
				))}
				</Select>
			</FormControl>
			<br/>
			<br/>
			<FormControl style={{ width: '100%' }}>
				<InputLabel id="demo-simple-select-standard-label">Província</InputLabel>
				<Select
					labelId="demo-simple-select-standard-label"
					id="demo-simple-select-standard"
					value={filterOptions.provincia || ''}
						multiple
					onChange={handleChange('provincia')}
					MenuProps={MenuProps}
				>
				{provincias?.map((option) => (
					<MenuItem key={option._id} value={option._id}>{option.fullName}</MenuItem>
				))}
				</Select>
			</FormControl>
			<br/>
			<br/>
			<FormControl style={{ width: '100%' }}>
				<InputLabel id="demo-simple-select-standard-label">Localidad</InputLabel>
				<Select
					labelId="demo-simple-select-standard-label"
					id="demo-simple-select-standard"
					value={filterOptions.localidad || ''}
					multiple
					onChange={handleChange('localidad')}
					MenuProps={MenuProps}
				>
				{localidades?.map((option) => (
					<MenuItem key={option._id} value={option._id}>{option.fullName}</MenuItem>
				))}
				</Select>
			</FormControl>
			<br/>
			<br/>
			<TextField
				id={`cp`}
				label='Código postal'
				value={filterOptions.cp || ''}
				onChange={handleChange('cp')}
				InputLabelProps={{
					shrink: true,
				}}
				margin='normal'
				variant='outlined'
			/>
			<br />
			<br />
		</>
	}

	return (
		<>
			<Card>
				<CardHeader title='Lista de Puntos'>
					<CardHeaderToolbar>
						<button
							type='button'
							className='btn btn-primary'
							onClick={() => history.push('/edit-punto')}>
							Añadir nuevo
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<FiltersCard 
						filtersContent={renderFiltersContent}
						collapsed={collapsed} 
						setCollapsed={setCollapsed}
						handleClearFilters={handleClearFilters}
						handleSearch={handleSearch}
					/>

					<Table
						data={getData(filteredData, localidades, entidades, comunidades, provincias)}
						columns={columns}
					/>

					<ConfirmDialog
						title={`¿Estás seguro de que quieres ${puntoId?.active ? 'desactivar' : 'activar'} este punto?`}
						open={openConfirmDialog === 1}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							changeStatusPunto(puntoId.id, !puntoId?.active)
								.then((res) => {
									if (res.status === 200) {
										alertSuccess({ title: `${puntoId?.active ? '¡Desactivado!' : '¡Activado!'}`, customMessage: `Punto ${puntoId?.active ? 'desactivado' : 'activado'} correctamente` })
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({ error: error, customMessage: `No se ha podido ${puntoId?.active ? 'desactivar' : 'activar'} este punto.` })
								});
						}}
					/>
					<ConfirmDialog
						title={'¿Estás seguro de que quieres eliminar este punto?'}
						open={openConfirmDialog === 2}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							deletePunto(puntoId)
								.then((res) => {
									if (res.status === 204 || res.status === 200) {
										alertSuccess({
											title: '¡Eliminado!',
											customMessage: 'Punto eliminado correctamente.',
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage: 'No se ha podido eliminar el punto.',
									});
								});
						}}
					/>
				</CardBody>
			</Card>
		</>
	);
}
