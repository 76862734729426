/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react'
import { useLocation } from 'react-router'
import { NavLink } from 'react-router-dom'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl, checkIsActive } from '../../../../_helpers'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import RoomIcon from '@material-ui/icons/Room'
import PublicIcon from '@material-ui/icons/Public'
import DescriptionIcon from '@material-ui/icons/Description'
import BusinessIcon from '@material-ui/icons/Business'
import { shallowEqual, useSelector } from 'react-redux'

export function AsideMenuList({ layoutProps }) {
	const user = useSelector((store) => store.authentication?.user, shallowEqual)
	const location = useLocation()
	const getMenuItemActive = (url, hasSubmenu = false) => {
		return checkIsActive(location, url)
			? ` ${!hasSubmenu &&
          'menu-item-active'} menu-item-open menu-item-not-hightlighted`
			: ''
	}

	return (
		<>
			{/* begin::Menu Nav */}
			<ul className={`menu-nav ${layoutProps.ulClasses}`}>
				<li
					className={`menu-item ${getMenuItemActive('/dashboard', false)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/dashboard">
						<span className="svg-icon menu-icon">
							<SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
						</span>
						<span className="menu-text">Panel</span>
					</NavLink>
				</li>
				<li className="menu-section">
					<h4 className="menu-text">PRINCIPAL</h4>
					<i className="menu-icon ki ki-bold-more-hor icon-md"></i>
				</li>
				<li
					className={`menu-item ${getMenuItemActive('/puntos', false)} ${getMenuItemActive('/edit-punto', false)}`}
					aria-haspopup="true">
					<NavLink className='menu-link' to='/puntos'>
						<span className='menu-icon'>
							<RoomIcon />
						</span>
						<span className='menu-text'>Puntos</span>
					</NavLink>
				</li>
				{/*<li
					className={`menu-item ${getMenuItemActive('/articles', false)} ${getMenuItemActive('/edit-article', false)}`}
					aria-haspopup="true">
					<NavLink className='menu-link' to='/articles'>
						<span className='menu-icon'>
							<DescriptionIcon />
						</span>
						<span className='menu-text'>Artículos</span>
					</NavLink>
				</li>*/}
				<li className="menu-section">
					<h4 className="menu-text">OTROS</h4>
					<i className="menu-icon ki ki-bold-more-hor icon-md"></i>
				</li>
				<li
					className={`menu-item ${getMenuItemActive('/entidades', false)} ${getMenuItemActive('/edit-entidad', false)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/entidades">
						<span className="menu-icon">
							<BusinessIcon/>
						</span>
						<span className="menu-text">Entidades</span>
					</NavLink>
				</li>
				{user?.role === 'admin' && <li
					className={`menu-item ${getMenuItemActive('/users', false)} ${getMenuItemActive('/edit-user', false)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/users">
						<span className="menu-icon">
							<AssignmentIndIcon/>
						</span>
						<span className="menu-text">Usuarios</span>
					</NavLink>
				</li>}
				<li
					className={`menu-item ${getMenuItemActive('/comunidades', false)} ${getMenuItemActive('/edit-comunidad', false)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/comunidades">
						<span className="menu-icon">
							<PublicIcon/>
						</span>
						<span className="menu-text">Comunidades autónomas</span>
					</NavLink>
				</li>
				<li
					className={`menu-item ${getMenuItemActive('/provincias', false)} ${getMenuItemActive('/edit-provincia', false)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/provincias">
						<span className="menu-icon">
							<PublicIcon/>
						</span>
						<span className="menu-text">Províncias</span>
					</NavLink>
				</li>
				<li
					className={`menu-item ${getMenuItemActive('/localidades', false)} ${getMenuItemActive('/edit-localidad', false)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/localidades">
						<span className="menu-icon">
							<PublicIcon/>
						</span>
						<span className="menu-text">Localidades</span>
					</NavLink>
				</li>
				{user?.role === 'admin' && <li
					className={`menu-item ${getMenuItemActive('/texts', false)} ${getMenuItemActive('/edit-text', false)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/texts">
						<span className="menu-icon">
							<DescriptionIcon/>
						</span>
						<span className="menu-text">Textos legales</span>
					</NavLink>
				</li>}
			</ul>

			{/* end::Menu Nav */}
		</>
	)
}
