import React, { useEffect, useState } from 'react';
import { alertError } from '../../utils/logger';
import { getPuntos } from '../../api/punto';
//import { getArticles } from '../../api/article';
import { useHistory } from 'react-router-dom';
import RoomIcon from '@material-ui/icons/Room'
//import DescriptionIcon from '@material-ui/icons/Description'

export function DashboardPage() {
	const [data, setData] = useState([]);
    const history = useHistory()
	
	useEffect(() => {
		async function fetchData() {
			try {
				const puntos = await getPuntos()
				//const articulos = await getArticles()
				setData({
					//articulos: articulos.data.length,
					puntos: puntos.data.length
				})
			} catch (err) {
				alertError({ error: err, customMessage: 'No se han podido obtener las estadísticas.'})
			}
		}
		fetchData()
	}, []);

	return (
		<div className='row justify-content-center'>
			<div className='col-12 col-md-4 text-center my-2' role='button' onClick={() => history.push('/users')}>
				<div className='card'>
					<div className='card-body'>
						<h5 className='card-title'><RoomIcon/>  Puntos</h5>
						<div className='contentDash'>{data.puntos}</div>
					</div>
				</div>
			</div>
			{/*<div className='col-12 col-md-4 text-center my-2' role='button' onClick={() => history.push('/users')}>
				<div className='card'>
					<div className='card-body'>
						<h5 className='card-title'><DescriptionIcon/>  Artículos</h5>
						<div className='contentDash'>{data.articulos}</div>
					</div>
				</div>
			</div>*/}
		</div>
	)
}
