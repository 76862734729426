import React, { useState, useEffect } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
} from '../../../../_metronic/_partials/controls';
import {
	Button,
	TextField,
	MuiThemeProvider,
	createMuiTheme,
	Tooltip,
	FormControlLabel,
	Checkbox
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import {
	deleteArticle,
	getArticleById,
	postArticle,
	updateArticle,
} from '../../../../api/article';
import { useSkeleton } from '../../../hooks/useSkeleton';
import { alertError, alertSuccess } from '../../../../utils/logger';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import Editor from '../../../components/editor/Editor'
import { checkIsEmpty } from '../../../../utils/helpers';
import { Visibility } from '@material-ui/icons';
import PreviewDialog from '../../../components/dialogs/PreviewDialog';
import { SERVER_URL } from '../../../../api/index';
import { buttonsStyle } from '../../../components/tables/table';

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: '#F64E60',
		},
	},
});

function getEmptyArticle() {
	return {
		title: '',
		text: '',
		summary: '',
		imageURL: '',
		videoURL: '',
		fileURL: '',
		link: '',
		publication_date: (new Date()).toISOString(),
		active: true
	};
}

export default function EditArticlesPage() {
	const [article, setArticle] = useState(getEmptyArticle());
	const [selectedImage, setSelectedImage] = useState(null);
	const [selectedFile, setSelectedFile] = useState(null);
	const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
	const [openPreviewFile, setOpenPreviewFile] = useState(false);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const articleId = useParams().id;
	const history = useHistory();

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		if (!articleId) {
			disableLoadingData();
			return;
		}
		getArticleById(articleId)
			.then((res) => {
				if (res.status === 200) {
					setArticle(res.data);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({ error: error, customMessage: 'No se ha podido obtener el artículo.' });
				history.push('/articles');
			});
	}, [articleId, disableLoadingData, history]);

	function saveArticle() {
		if (checkIsEmpty(article.title)) return alertError({ error: null, customMessage: 'El título es requerido.' })
		if (checkIsEmpty(article.text)) return alertError({ error: null, customMessage: 'El texto es requerido.' })
		if (checkIsEmpty(article.summary)) return alertError({ error: null, customMessage: 'El resumen es requerido.' })
		if (checkIsEmpty(article.publication_date)) return alertError({ error: null, customMessage: 'La fecha de publicación es requerida.' })
		if (article.link) {
			try {
				if (!Boolean(new URL(article.link)))
					return alertError({
						error: null,
						customMessage: 'El enlace no es válido.',
					});
			} catch (error) {
				return alertError({
					error: null,
					customMessage: 'El enlace no es válido.',
				});
			}
		}
		if (!articleId) {
			postArticle(article, selectedImage, selectedFile)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: '¡Guardado!',
							customMessage: 'Artículo creado correctamente.',
						});
						history.push('/articles');
					}
				})
				.catch((error) => {
					alertError({ error: error, customMessage: 'No se ha podido guardar el artículo.' });
				});
		} else {
			updateArticle(articleId, article, selectedImage, selectedFile)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: '¡Guardado!',
							customMessage: 'Los cambios se han guardado correctamente.',
						});
						history.push('/articles');
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'No se han podido guardar los cambios.',
					});
				});
		}
	}

	const handleChangeEditor = (element, value) => {
		setArticle({ ...article, [element]: value })
	}

	const handleChange = (element, lang) => (event) => {
		if (event.target.value === ' ') return
		if (lang) {
			if (!article[element]) article[element] = {}
			let newText = article[element]
			newText[lang] = event.target.value
			setArticle({ ...article, [element]: newText })
		} else setArticle({ ...article, [element]: event.target.value });
	};

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title='Editar artículo'></CardHeader>
					<CardBody>
						<TextField
							id={`title`}
							label="Título"
							value={article.title}
							onChange={handleChange('title')}
							InputLabelProps={{
							shrink: true
							}}
							margin="normal"
							variant="outlined"
							required
						/>
						<Editor
							body={article.summary || ''}
							setBody={new_body => handleChangeEditor('summary', new_body)}
							className='max-height'
							placeholder={'Ingrese el resumen aquí...'}
							label="Resumen"
						/>
						<br/>
						<Editor
							body={article.text || ''}
							setBody={new_body => handleChangeEditor('text', new_body)}
							className='max-height'
							placeholder={'Ingrese el texto aquí...'}
							label="Texto"
						/>
						<TextField
							id="publication_date"
							label="Fecha de publicación (España):"
							type="date"
							value={article.publication_date && article.publication_date?.includes('T') ? article.publication_date.split('T')[0] : article.publication_date}
							onChange={handleChange('publication_date')}
							InputLabelProps={{
								shrink: true
							}}
							margin="normal"
							variant="outlined"
						/>
						<br />
						<TextField
							id={`videoURL`}
							label='Id de Youtube Video'
							value={article.videoURL || ''}
							onChange={handleChange('videoURL')}
							InputLabelProps={{
								shrink: true,
							}}
							type='url'
							margin='normal'
							variant='outlined'
						/>
						<br />
						<TextField
							id={`link`}
							label='Enlace'
							value={article.link || ''}
							onChange={handleChange('link')}
							InputLabelProps={{
								shrink: true,
							}}
							type='url'
							margin='normal'
							variant='outlined'
						/>
						<label htmlFor={'upload-image'}>
							<input
								style={{ display: 'none' }}
								id={'upload-image'}
								name={'upload-image'}
								type='file'
								accept={'image/*'}
								onChange={(e) => {
									setSelectedImage(e.target.files[0]);
								}}
							/>
							<Button
								style={{ marginRight: '15px' }}
								color='secondary'
								component='span'
								variant='outlined'>
								{selectedImage || article.imageURL !== ''
									? 'Cambiar imagen'
									: 'Subir imagen'}
							</Button>
						</label>
						{(selectedImage || article.imageURL !== '') && (
							<>
								<Tooltip title={'Visualizar imagen'}>
									<Button
										size='small'
										onClick={() => setOpenPreviewDialog(true)}
										style={{ ...buttonsStyle, marginRight: '15px' }}>
										<Visibility />
									</Button>
								</Tooltip>
								<PreviewDialog
									title={'Visualizar imagen'}
									open={openPreviewDialog}
									setOpen={setOpenPreviewDialog}
									src={
										selectedImage
											? URL.createObjectURL(selectedImage)
											: `${SERVER_URL}/${article.imageURL}`
									}
								/>
								<span>
									{selectedImage
										? selectedImage?.name
										: article.imageURL !== ''
										? article.imageURL.split(/-(.*)/s)[1]
										: ''}
								</span>
							</>
						)}
						<br />
						<br />
						<label htmlFor={'upload-file'}>
							<input
								style={{ display: 'none' }}
								id={'upload-file'}
								name={'upload-file'}
								type='file'
								onChange={(e) => {
									setSelectedFile(e.target.files[0]);
								}}
							/>
							<Button
								style={{ marginRight: '15px' }}
								color='secondary'
								component='span'
								variant='outlined'>
								{selectedFile || article.fileURL !== ''
									? 'Cambiar archivo'
									: 'Subir archivo'}
							</Button>
						</label>
						{(selectedFile || (article.fileURL && article.fileURL !== '')) && (
							<>
								<Tooltip title={'Visualizar archivo'}>
									<Button
										size='small'
										onClick={() => setOpenPreviewFile(true)}
										style={{ ...buttonsStyle, marginRight: '15px' }}>
										<Visibility />
									</Button>
								</Tooltip>
								<PreviewDialog
									title={'Visualizar archivo'}
									open={openPreviewFile}
									setOpen={setOpenPreviewFile}
									src={
										selectedFile
											? URL.createObjectURL(selectedFile)
											: `${SERVER_URL}/${article.fileURL}`
									}
								/>
								<span>
									{selectedFile
										? selectedFile?.name
										: article.fileURL !== ''
										? article.fileURL.split(/-(.*)/s)[1]
										: ''}
								</span>
							</>
						)}
						<br/>
						<FormControlLabel
							control={<Checkbox checked={article.active} onChange={() => setArticle({ ...article, active: !article.active })} name="checkActive" />}
							label="Activo"
						/>
					</CardBody>
				</Card>
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<Button
						onClick={() => history.push('/articles')}
						variant='outlined'
						style={{ marginRight: '20px' }}>
						Atrás
					</Button>
					<Button
						onClick={() => saveArticle()}
						variant='outlined'
						color='primary'
						style={{ marginRight: '20px' }}>
						Guardar artículo
					</Button>
					{articleId && (
						<>
							<MuiThemeProvider theme={theme}>
								<Button
									onClick={() => setOpenConfirmDialog(true)}
									variant='outlined'
									color='secondary'
									style={{ marginRight: '20px' }}>
									Eliminar artículo
								</Button>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										marginLeft: 'auto',
									}}></div>
							</MuiThemeProvider>

							<ConfirmDialog
								title={'¿Estás seguro de que quieres eliminar este artículo?'}
								open={openConfirmDialog}
								setOpen={setOpenConfirmDialog}
								onConfirm={() => {
									deleteArticle(articleId)
										.then((res) => {
											if (res.status === 204 || res.status === 200) {
												alertSuccess({
													title: '¡Eliminado!',
													customMessage: 'Artículo eliminado correctamente.',
												});
												history.push('/articles');
											}
										})
										.catch((error) => {
											alertError({
												error: error,
												customMessage: 'No se ha podido eliminar el artículo.',
											});
										});
								}}
							/>
						</>
					)}
				</div>
			</>
		);
}
