import React, { useEffect, useState } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from '../../../../_metronic/_partials/controls';
import Table, {
	dateFormatter,
	buttonsStyle
} from '../../../components/tables/table';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import { getEntidades, deleteEntidad, changeStatusEntidad } from '../../../../api/entidad';
import { Button, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { alertError, alertSuccess } from '../../../../utils/logger';
import { useHistory } from 'react-router-dom';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import { shallowEqual, useSelector } from 'react-redux';

function getData(entidades) {
	let data = [];
	for (let i = 0; i < entidades.length; ++i) {
		console.log(entidades);
		const elem = {};
		elem.name = entidades[i].name;
		elem.createdAt = entidades[i].createdAt;
		elem.id = entidades[i]._id;
		elem.active = entidades[i].active;
		data = data.concat(elem);
	}
	return data;
}

export default function EntidadesPage() {
	const [data, setData] = useState([]);
	const [entidadId, setEntidadId] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
	const [refresh, setRefresh] = useState(false);
	const history = useHistory();
	const user = useSelector((store) => store.authentication?.user, shallowEqual)

	function buttonFormatter(cell) {
		const elem = data.find(item => item.id === cell)
		return (
			<>
				<Tooltip title="Editar">
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => history.push('/edit-entidad/' + cell)}>
						<EditIcon />
					</Button>
				</Tooltip>
				{user?.role === 'admin' && <>
					<Tooltip title={elem?.active ? "Desactivar" : "Activar"}>
						<Button
							style={buttonsStyle} size="small"
							onClick={() => {
								setEntidadId(elem)
								setOpenConfirmDialog(1)
							}}>
							{elem?.active ? <ToggleOffIcon/> : <ToggleOnIcon style={{color :'red'}}/>}
						</Button>
					</Tooltip>
					<Tooltip title="Eliminar">
						<Button
							style={buttonsStyle}
							size='small'
							onClick={() => {
								setEntidadId(cell);
								setOpenConfirmDialog(2);
							}}>
							<DeleteIcon />
						</Button>
					</Tooltip>
				</>}
			</>
		);
	}

	const columns = [
		{ dataField: 'name', text: 'Nombre', sort: true },
		{
			dataField: 'createdAt',
			text: 'Fecha de creación',
			formatter: dateFormatter,
			sort: true,
		},
		{ dataField: 'id', text: '', formatter: buttonFormatter },
	];

	useEffect(() => {
		getEntidades()
			.then((res) => {
				if (res.status === 200) {
					setData(getData(res.data));
					setRefresh(false);
				}
			})
			.catch((error) => {
				alertError({ error: error, customMessage: 'No se han podido obtener las entidades.' });
			});
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader title='Lista de Entidades'>
					<CardHeaderToolbar>
						<button
							type='button'
							className='btn btn-primary'
							onClick={() => history.push('/edit-entidad')}>
							Añadir nueva
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<Table data={data} columns={columns} />
					<ConfirmDialog
						title={`¿Estás seguro de que quieres ${entidadId?.active ? 'desactivar' : 'activar'} esta entidad?`}
						open={openConfirmDialog === 1}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							changeStatusEntidad(entidadId.id, !entidadId?.active)
								.then((res) => {
									if (res.status === 200) {
										alertSuccess({ title: `${entidadId?.active ? '¡Desactivada!' : '¡Activada!'}`, customMessage: `Entidad ${entidadId?.active ? 'desactivada' : 'activada'} correctamente` })
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({ error: error, customMessage: `No se ha podido ${entidadId?.active ? 'desactivar' : 'activar'} la entidad.` })
								});
						}}
					/>
					<ConfirmDialog
						title={'¿Estás seguro de que quieres eliminar esta entidad?'}
						open={openConfirmDialog === 2}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							deleteEntidad(entidadId)
								.then((res) => {
									if (res.status === 204 || res.status === 200) {
										alertSuccess({
											title: '¡Eliminado!',
											customMessage: 'Entidad eliminada correctamente.',
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage: 'No se ha podido eliminar la entidad.',
									});
								});
						}}
					/>
				</CardBody>
			</Card>
		</>
	);
}
