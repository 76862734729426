import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../../_metronic/_partials/controls'
import Table, { dateFormatter, buttonsStyle } from '../../../components/tables/table'
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog'
import { getUsers, deleteUser } from '../../../../api/user'
import { Button, Tooltip } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { alertError, alertSuccess } from '../../../../utils/logger'
import { useHistory } from 'react-router-dom'

function getData(users) {
  let data = []
  for (let i = 0; i < users.length; ++i) {
      const elem = {}
      elem.name = users[i].fullName
      elem.email = users[i].email
      elem.createdAt = users[i].createdAt
      elem.id = users[i]._id
      elem.role = users[i].role
      data = data.concat(elem)
  }
  return data
}

export default function UsersPage() {
  const [data, setData] = useState([])
  const [userId, setUserId] = useState(null)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const history = useHistory()

  function buttonFormatter(cell) {
    const elem = data.find(item => item.id === cell)
    return (<>
      <Tooltip title="Editar">
        <Button
          style={buttonsStyle} size="small"
          onClick={() => history.push('/edit-user/' + cell)}>
          <EditIcon/>
        </Button>
      </Tooltip>
      {elem && elem.role !== 'admin' && <Tooltip title="Eliminar">
        <Button
          style={buttonsStyle} size="small"
          onClick={() => {
            setUserId(cell)
            setOpenConfirmDialog(true)
          }}>
          <DeleteIcon/>
        </Button>
      </Tooltip>}
    </>)
  }

  function roleFormatter(cell) {
		return <>
			<span>
				{cell === 'admin' && <span className='badge badge-primary ml-2'>
					Administrador
				</span>}
			</span>
		</>
	}

  const columns = [
    { dataField: 'name', text: 'Nombre', sort: true },
    { dataField: 'email', text: 'Email', sort: true },
    { dataField: 'role', text: '', formatter: roleFormatter },
    { dataField: 'createdAt', text: 'Fecha de creación', formatter: dateFormatter, sort: true },
    { dataField: 'id', text: '', formatter: buttonFormatter }
  ]

  useEffect(() => {
    getUsers().then((res) => {
      if (res.status === 200) {
        setData(getData(res.data))
        setRefresh(false)
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'No se han podido obtener los usuarios.' })
    })
  }, [refresh])

  return (
    <>
      <Card>
        <CardHeader title='Users list'>
          <CardHeaderToolbar>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => history.push('/edit-user')}
              >
                Añadir nuevo
              </button>
            </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Table
            data={data}
            columns={columns}
          />
          <ConfirmDialog
            title={'¿Estás seguro de que quieres eliminar este usuario?'}
            open={openConfirmDialog}
            setOpen={setOpenConfirmDialog}
            onConfirm={() => {
              deleteUser(userId).then(res => {
                if (res.status === 204 || res.status === 200) {
                  alertSuccess({  title: '¡Eliminado!', customMessage: 'Usuario eliminado correctamente.' })
                  setRefresh(true)
                }
              }).catch(error => {
                alertError({ error: error, customMessage: 'No se ha podido eliminar el usuario.' })
              })
            }}
          />
        </CardBody>
      </Card>
    </>
  );
}
