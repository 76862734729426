import React from 'react'
import { Redirect, Switch } from 'react-router-dom'
import { ContentRoute } from '../_metronic/layout'
import { DashboardPage } from './pages/DashboardPage'
import { shallowEqual, useSelector } from 'react-redux'
import UsersPage from './modules/ContentManager/users/UsersPage'
import EditUsersPage from './modules/ContentManager/users/EditUsersPage'
import PuntosPage from './modules/ContentManager/puntos/PuntosPage'
import EditPuntosPage from './modules/ContentManager/puntos/EditPuntosPage'
import ArticlesPage from './modules/ContentManager/articles/ArticlesPage'
import EditArticlesPage from './modules/ContentManager/articles/EditArticlesPage'
import ComunidadesPage from './modules/ContentManager/comunidades/ComunidadesPage'
import EditComunidadesPage from './modules/ContentManager/comunidades/EditComunidadesPage'
import ProvinciasPage from './modules/ContentManager/provincias/ProvinciasPage'
import EditProvinciasPage from './modules/ContentManager/provincias/EditProvinciasPage'
import LocalidadesPage from './modules/ContentManager/localidades/LocalidadesPage'
import EditLocalidadesPage from './modules/ContentManager/localidades/EditLocalidadesPage'
import EntidadesPage from './modules/ContentManager/entidades/EntidadesPage'
import EditEntidadesPage from './modules/ContentManager/entidades/EditEntidadesPage'
import TextsPage from './modules/ContentManager/texts/TextsPage'
import EditTextsPage from './modules/ContentManager/texts/EditTextsPage'

export default function BasePage() {
	const user = useSelector((store) => store.authentication?.user, shallowEqual)
	const isAdmin = user?.role === 'admin'

	return (
		<Switch>
			{/* Redirect from root URL to /dashboard. */ }
			<Redirect exact from="/" to="/dashboard" />
			<ContentRoute path="/dashboard" component={DashboardPage} />

			{/* Users */}
			<ContentRoute from="/users" component={!isAdmin ? DashboardPage : UsersPage} />
			<ContentRoute from="/edit-user/:id?" component={!isAdmin ? DashboardPage : EditUsersPage} />

			{/* Puntos */}
			<ContentRoute from="/puntos" component={PuntosPage} />
			<ContentRoute from="/edit-punto/:id?" component={EditPuntosPage} />

			{/* Articles */}
			<ContentRoute from="/articles" component={ArticlesPage} />
			<ContentRoute from="/edit-article/:id?" component={EditArticlesPage} />

			{/* Comunidades */}
			<ContentRoute from="/comunidades" component={ComunidadesPage} />
			<ContentRoute from="/edit-comunidad/:id?" component={EditComunidadesPage} />

			{/* Provincias */}
			<ContentRoute from="/provincias" component={ProvinciasPage} />
			<ContentRoute from="/edit-provincia/:id?" component={EditProvinciasPage} />

			{/* Localidades */}
			<ContentRoute from="/localidades" component={LocalidadesPage} />
			<ContentRoute from="/edit-localidad/:id?" component={EditLocalidadesPage} />

			{/* Entidades */}
			<ContentRoute from="/entidades" component={EntidadesPage} />
			<ContentRoute from="/edit-entidad/:id?" component={EditEntidadesPage} />

			{/* Texts */}
			<ContentRoute from="/texts" component={!isAdmin ? DashboardPage : TextsPage} />
			<ContentRoute from="/edit-text/:id" component={!isAdmin ? DashboardPage : EditTextsPage} />


			<Redirect to="/error" />
		</Switch>
	)
}
