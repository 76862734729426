import axios from 'axios';
export const API_KEY = '8wN4ajLmidDP3ImfkTT5WiHjPFqKgx/FQcYG+Y9g+2U=';

export const baseClient = () => {
	return axios.create();
};

export const authClient = () => {
	return axios.create({
		withCredentials: true,
		headers: {
			Authorization: API_KEY,
		},
	});
};

const PRO =
	process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'pro';

export const API = PRO
	? 'https://api.punto-limpio.info/api/'
	: 'http://0.0.0.0:3000/api';

export const WEB_URL = PRO ? '' : 'http://localhost:3000/';

export const SERVER_URL = PRO
	? 'https://api.punto-limpio.info/api/'
	: 'http://localhost:3000';
