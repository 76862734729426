import { API, authClient } from '../index';

// Get all comunidades
export const getComunidades = () => {
	return authClient().get(`${API}/comunidad?getDisabled=true`);
};

// Get comunidad by id
export const getComunidadById = (id) => {
	return authClient().get(`${API}/comunidad/${id}`);
};

// Delete comunidad
export const deleteComunidad = (id) => {
	return authClient().delete(`${API}/comunidad/${id}`);
};

// Create comunidad 
export const postComunidad = async (comunidad) => {
	return authClient().post(`${API}/comunidad`, comunidad);
};

// Update comunidad
export const updateComunidad = async (id, comunidad) => {
	return authClient().put(`${API}/comunidad/${id}`, comunidad);
};

// Change status comunidad
export const changeStatusComunidad = async (id, active) => {
	return authClient().put(`${API}/comunidad/change-status/${id}`, { active });
};