import React, { useState, useEffect } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
} from '../../../../_metronic/_partials/controls';
import {
	Button,
	TextField,
	MuiThemeProvider,
	createMuiTheme,
	FormControlLabel,
	Checkbox
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import {
	deleteEntidad,
	getEntidadById,
	postEntidad,
	updateEntidad,
} from '../../../../api/entidad';
import { useSkeleton } from '../../../hooks/useSkeleton';
import { alertError, alertSuccess } from '../../../../utils/logger';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import { checkIsEmpty } from '../../../../utils/helpers';

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: '#F64E60',
		},
	},
});

function getEmptyEntidad() {
	return {
		name: '',
		active: true
	};
}

export default function EditEntidadesPage() {
	const [entidad, setEntidad] = useState(getEmptyEntidad());
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const entidadId = useParams().id;
	const history = useHistory();

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		if (!entidadId) {
			disableLoadingData();
			return;
		}
		getEntidadById(entidadId)
			.then((res) => {
				if (res.status === 200) {
					setEntidad(res.data);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({ error: error, customMessage: 'No se ha podido obtener la entidad.' });
				history.push('/entidades');
			});
	}, [entidadId, disableLoadingData, history]);

	function saveEntidad() {
		if (checkIsEmpty(entidad.name)) return alertError({ error: null, customMessage: 'El nombre es requerido.' })
		if (!entidadId) {
			postEntidad(entidad)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: '¡Guardado!',
							customMessage: 'Entidad creada correctamente.',
						});
						history.push('/entidades');
					}
				})
				.catch((error) => {
					alertError({ error: error, customMessage: 'No se ha podido guardar la entidad.' });
				});
		} else {
			updateEntidad(entidadId, entidad)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: '¡Guardado!',
							customMessage: 'Los cambios se han guardado correctamente.',
						});
						history.push('/entidades');
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'No se han podido guardar los cambios.',
					});
				});
		}
	}

	const handleChange = (element, lang) => (event) => {
		if (event.target.value === ' ') return
		if (lang) {
			if (!entidad[element]) entidad[element] = {}
			let newText = entidad[element]
			newText[lang] = event.target.value
			setEntidad({ ...entidad, [element]: newText })
		} else setEntidad({ ...entidad, [element]: event.target.value });
	};

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title='Editar entidad'></CardHeader>
					<CardBody>
						<TextField
							id={`name`}
							label="Nombre"
							value={entidad.name}
							onChange={handleChange('name')}
							InputLabelProps={{
							shrink: true
							}}
							margin="normal"
							variant="outlined"
							required
						/>
						<br/>
						<FormControlLabel
							control={<Checkbox checked={entidad.active} onChange={() => setEntidad({ ...entidad, active: !entidad.active })} name="checkActive" />}
							label="Activa"
						/>
					</CardBody>
				</Card>
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<Button
						onClick={() => history.push('/entidades')}
						variant='outlined'
						style={{ marginRight: '20px' }}>
						Atrás
					</Button>
					<Button
						onClick={() => saveEntidad()}
						variant='outlined'
						color='primary'
						style={{ marginRight: '20px' }}>
						Guardar entidad
					</Button>
					{entidadId && (
						<>
							<MuiThemeProvider theme={theme}>
								<Button
									onClick={() => setOpenConfirmDialog(true)}
									variant='outlined'
									color='secondary'
									style={{ marginRight: '20px' }}>
									Eliminar entidad
								</Button>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										marginLeft: 'auto',
									}}></div>
							</MuiThemeProvider>

							<ConfirmDialog
								title={'¿Estás seguro de que quieres eliminar esta entidad?'}
								open={openConfirmDialog}
								setOpen={setOpenConfirmDialog}
								onConfirm={() => {
									deleteEntidad(entidadId)
										.then((res) => {
											if (res.status === 204 || res.status === 200) {
												alertSuccess({
													title: '¡Eliminada!',
													customMessage: 'Entidad eliminada correctamente',
												});
												history.push('/entidades');
											}
										})
										.catch((error) => {
											alertError({
												error: error,
												customMessage: 'No se ha podido eliminar la entidad.',
											});
										});
								}}
							/>
						</>
					)}
				</div>
			</>
		);
}
