import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader } from '../../../../_metronic/_partials/controls'
import {
  Button,
  TextField,
  MuiThemeProvider,
  createMuiTheme
} from '@material-ui/core'
import Select from 'react-select';
import { useHistory, useParams } from 'react-router-dom'
import { deleteLocalidad, getLocalidadById, postLocalidad, updateLocalidad } from '../../../../api/localidad'
import { useSkeleton } from '../../../hooks/useSkeleton'
import { alertError, alertSuccess } from '../../../../utils/logger'
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog'
import { getProvincias } from '../../../../api/provincia'
import { shallowEqual, useSelector } from 'react-redux'

// Create theme for delete button (red)
const theme = createMuiTheme({
  palette: {
    secondary: {
      main: '#F64E60'
    }
  }
})

function getEmptyLocalidad() {
  return {
    fullName: '',
    provincia: '',
    code: ''
  }
}

export default function EditLocalidadesPage() {
  const user = useSelector((store) => store.authentication?.user, shallowEqual)
  const [localidad, setLocalidad] = useState(getEmptyLocalidad())
  const [provincias, setProvincias] = useState(null)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const localidadId = useParams().id
  const history = useHistory()

  const { isLoading: isLoadingData, disableLoading: disableLoadingData, ContentSkeleton } = useSkeleton()

  useEffect(() => {
    getProvincias().then(res => {
      if (res.status === 200) {
        setProvincias(res.data)
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'No se han podido obtener las provincias.' })
      history.push('/localidades')
    })
    if (!localidadId) {
      disableLoadingData()
      return
    }
    getLocalidadById(localidadId).then(res => {
      if (res.status === 200) {
        const user = res.data
        delete user.password
        setLocalidad(user)
        disableLoadingData()
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'No se ha podido obtener localidad.' })
      history.push('/localidades')
    })
  }, [localidadId, disableLoadingData, history])

  function saveLocalidad() {
    if (!localidadId) {
      postLocalidad(localidad).then(res => {
        if (res.status === 201) {
          alertSuccess({ title: '¡Guardado!', customMessage: 'Localidad creada correctamente.' })
          history.push('/localidades')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'No se ha podido guardar localidad.' })
      })
    } else {
      updateLocalidad(localidadId, localidad).then(res => {
        if (res.status === 200) {
          alertSuccess({ title: '¡Guardado!', customMessage: 'Los cambios se han guardado correctamente.' })
          history.push('/localidades')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'No se han podido guardar los cambios.' })
      })
    }
  }

  const handleChange = (element) => (event) => {
    if (element === 'provincia') {
      const code = provincias.find(item => item._id === event._id).code
      setLocalidad({ ...localidad, [element]: event._id, code: code + '.' })
    }
    else setLocalidad({ ...localidad, [element]: event.target.value })
  }

  if (isLoadingData)
    return <ContentSkeleton />
  else return (
    <>
      <Card>
        <CardHeader title='Editar localidad'>
        </CardHeader>
        <CardBody>
          <TextField
            id={`fullName`}
            label="Localidad"
            value={localidad.fullName}
            onChange={handleChange('fullName')}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            required
          />
          <span>Província</span>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            className="my-2"
            defaultValue={localidad.provincia && provincias ? provincias.find(item => item._id === localidad.provincia) : ''}
            onChange={handleChange('provincia')}
            options={provincias}
            getOptionLabel={(option) => `${option.fullName}`}
            getOptionValue={(option) => `${option._id}`}
            placeholder="Seleccione una provincia..."
            styles={{
              // Fixes the overlapping problem of the component
              menu: provided => ({ ...provided, zIndex: 9999 })
            }}
          />
        </CardBody>
      </Card>
      <Button
        onClick={() => history.push('/localidades')}
        variant="outlined"
        style={{ marginRight: '20px' }}>
        Atrás
      </Button>
      <Button
        onClick={() => saveLocalidad()}
        variant="outlined"
        color="primary"
        style={{ marginRight: '20px' }}>
        Guardar localidad
      </Button>
      {localidadId && (user?.role === 'admin') && <>
        <MuiThemeProvider theme={theme}>
          <Button
            onClick={() => setOpenConfirmDialog(true)}
            variant="outlined"
            color="secondary">
        Eliminar localidad
          </Button>
        </MuiThemeProvider>

        <ConfirmDialog
          title={'¿Estás seguro de que quieres eliminar esta localidad?'}
          open={openConfirmDialog}
          setOpen={setOpenConfirmDialog}
          onConfirm={() => {
            deleteLocalidad(localidadId).then(res => {
              if (res.status === 204) {
                alertSuccess({ title: '¡Eliminado!', customMessage: 'Localidad eliminada correctamente' })
                history.push('/localidades')
              }
            }).catch(error => {
              alertError({ error: error, customMessage: 'No se ha podido eliminar la localidad.' })
            })
          }}
        />
      </>}
    </>
  );
}
