import { API, authClient } from '../index';
import { uploadSingleFile } from "../files"

// Get all articles
export const getArticles = () => {
	return authClient().get(`${API}/article?getDisabled=true`);
};

// Get article by id
export const getArticleById = (id) => {
	return authClient().get(`${API}/article/${id}`);
};

// Delete article
export const deleteArticle = (id) => {
	return authClient().delete(`${API}/article/${id}`);
};

// Create article 
export const postArticle = async (article, image, file) => {
	if (image) {
		const response = await uploadSingleFile(image, 'articles')
		article.imageURL = response.data.fileURL
	}
	if (file) {
		const response = await uploadSingleFile(file, 'articles');
		article.fileURL = response.data.fileURL;
	}
	return authClient().post(`${API}/article`, article);
};

// Update article
export const updateArticle = async (id, article, image, file) => {
	if (image) {
		const response = await uploadSingleFile(image, 'articles')
		article.imageURL = response.data.fileURL
	}
	if (file) {
		const response = await uploadSingleFile(file, 'articles');
		article.fileURL = response.data.fileURL;
	}
	return authClient().put(`${API}/article/${id}`, article);
};

// Change status article
export const changeStatusArticle = async (id, active) => {
	return authClient().put(`${API}/article/change-status/${id}`, { active });
};