import { API, authClient } from '../index';

// Get all articles
export const getPuntos = () => {
	return authClient().get(`${API}/punto?getDisabled=true`);
};

// Get article by id
export const getPuntoById = (id) => {
	return authClient().get(`${API}/punto/${id}`);
};

// Delete article
export const deletePunto = (id) => {
	return authClient().delete(`${API}/punto/${id}`);
};

// Create article 
export const postPunto = async (punto) => {
	return authClient().post(`${API}/punto`, punto);
};

// Update article
export const updatePunto = async (id, punto) => {
	return authClient().put(`${API}/punto/${id}`, punto);
};

// Change status article
export const changeStatusPunto = async (id, active) => {
	return authClient().put(`${API}/punto/change-status/${id}`, { active });
};