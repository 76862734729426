import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader } from '../../../../_metronic/_partials/controls'
import {
  Button,
  TextField,
  MuiThemeProvider,
  createMuiTheme,
} from '@material-ui/core'
import Select from 'react-select';
import { useHistory, useParams } from 'react-router-dom'
import { deleteProvincia, getProvinciaById, postProvincia, updateProvincia } from '../../../../api/provincia'
import { useSkeleton } from '../../../hooks/useSkeleton'
import { alertError, alertSuccess } from '../../../../utils/logger'
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog'
import { getComunidades } from '../../../../api/comunidad'
import { shallowEqual, useSelector } from 'react-redux'

// Create theme for delete button (red)
const theme = createMuiTheme({
  palette: {
    secondary: {
      main: '#F64E60'
    }
  }
})

function getEmptyProvincia() {
  return {
    fullName: '',
    comunidad: ''
  }
}

export default function EditProvinciasPage() {
  const user = useSelector((store) => store.authentication?.user, shallowEqual)
  const [provincia, setProvincia] = useState(getEmptyProvincia())
  const [comunidades, setComunidades] = useState(null)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const provinciaId = useParams().id
  const history = useHistory()

  const { isLoading: isLoadingData, disableLoading: disableLoadingData, ContentSkeleton } = useSkeleton()

  useEffect(() => {
    getComunidades().then(res => {
      if (res.status === 200) {
        setComunidades(res.data)
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'No se han podido obtener las comunidades.' })
      history.push('/provincias')
    })
    if (!provinciaId) {
      disableLoadingData()
      return
    }
    getProvinciaById(provinciaId).then(res => {
      if (res.status === 200) {
        setProvincia(res.data)
        disableLoadingData()
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'No se ha podido obtener la província.' })
      history.push('/provincias')
    })
  }, [provinciaId, disableLoadingData, history])

  function saveProvincia() {
    if (!provinciaId) {
      postProvincia(provincia).then(res => {
        if (res.status === 201) {
          alertSuccess({ title: '¡Guardado!', customMessage: 'Província creada correctamente.' })
          history.push('/provincias')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'No se ha podido guardar la província.' })
      })
    } else {
      updateProvincia(provinciaId, provincia).then(res => {
        if (res.status === 200) {
          alertSuccess({ title: '¡Guardado!', customMessage: 'Los cambios se han guardado correctamente.' })
          history.push('/provincias')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'No se han podido guardar los cambios.' })
      })
    }
  }

  const handleChange = (element) => (event) => {
    if (element === 'comunidad') {
      setProvincia({ ...provincia, [element]: event._id})
    }
    else setProvincia({ ...provincia, [element]: event.target.value })
  }

  if (isLoadingData)
    return <ContentSkeleton />
  else return (
    <>
      <Card>
        <CardHeader title='Editar província'>
        </CardHeader>
        <CardBody>
          <TextField
            id={`fullName`}
            label="Província"
            value={provincia.fullName}
            onChange={handleChange('fullName')}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            required
          />
          <span>Comunidad</span>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            className="my-2"
            defaultValue={provincia.comunidad && comunidades ? comunidades.find(item => item._id === provincia.comunidad) : ''}
            onChange={handleChange('comunidad')}
            options={comunidades}
            getOptionLabel={(option) => `${option.name}`}
            getOptionValue={(option) => `${option._id}`}
            placeholder="Seleccione una comunidad..."
            styles={{
              // Fixes the overlapping problem of the component
              menu: provided => ({ ...provided, zIndex: 9999 })
            }}
          />
        </CardBody>
      </Card>
      <Button
        onClick={() => history.push('/provincias')}
        variant="outlined"
        style={{ marginRight: '20px' }}>
        Atrás
      </Button>
      <Button
        onClick={() => saveProvincia()}
        variant="outlined"
        color="primary"
        style={{ marginRight: '20px' }}>
        Guardar provincia
      </Button>
      {provinciaId && (user?.role === 'admin') && <>
        <MuiThemeProvider theme={theme}>
          <Button
            onClick={() => setOpenConfirmDialog(true)}
            variant="outlined"
            color="secondary">
            Eliminar provincia
          </Button>
        </MuiThemeProvider>

        <ConfirmDialog
          title={'¿Estás seguro de que quieres eliminar esta provincia?'}
          open={openConfirmDialog}
          setOpen={setOpenConfirmDialog}
          onConfirm={() => {
            deleteProvincia(provinciaId).then(res => {
              if (res.status === 204) {
                alertSuccess({  title: '¡Eliminada!', customMessage: 'Provincia eliminada correctamente.' })
                history.push('/provincias')
              }
            }).catch(error => {
              alertError({ error: error, customMessage: 'No se ha podido eliminar la provincia.' })
            })
          }}
        />
      </>}
    </>
  );
}
