import { API, authClient } from '../index';

// Get all entidades
export const getEntidades = () => {
	return authClient().get(`${API}/entidad?getDisabled=true`);
};

// Get entidad by id
export const getEntidadById = (id) => {
	return authClient().get(`${API}/entidad/${id}`);
};

// Delete entidad
export const deleteEntidad = (id) => {
	return authClient().delete(`${API}/entidad/${id}`);
};

// Create entidad 
export const postEntidad = async (entidad) => {
	return authClient().post(`${API}/entidad`, entidad);
};

// Update entidad
export const updateEntidad = async (id, entidad) => {
	return authClient().put(`${API}/entidad/${id}`, entidad);
};

// Change status entidad
export const changeStatusEntidad = async (id, active) => {
	return authClient().put(`${API}/entidad/change-status/${id}`, { active });
};