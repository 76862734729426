import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../../_metronic/_partials/controls'
import Table, { dateFormatter, buttonsStyle } from '../../../components/tables/table'
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog'
import { getLocalidades, deleteLocalidad } from '../../../../api/localidad'
import { Button, Tooltip, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { alertError, alertSuccess } from '../../../../utils/logger'
import { useHistory } from 'react-router-dom'
import { getProvincias } from '../../../../api/provincia'
import { getComunidades } from '../../../../api/comunidad'
import { shallowEqual, useSelector } from 'react-redux'
import FiltersCard from '../../../components/filters/Filter';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: () => null,
};

function getData(localidades, provincias, comunidades) {
  let data = []
  for (let i = 0; i < localidades.length; ++i) {
      const elem = {}
      const provincia = provincias.find(cat => cat._id === localidades[i].provincia)
      const comunidad = provincia ? comunidades.find(cat => cat._id === provincia.comunidad) : null
      elem.name = localidades[i].fullName
      elem.comunidad = comunidad ? comunidad.name : '---'
      elem.provincia = provincia ? provincia.fullName : '---'
      elem.createdAt = localidades[i].createdAt
      elem.id = localidades[i]._id
      data = data.concat(elem)
  }
  return data
}

const initialFilters = {
	comunidad: [],
  provincia: []
}

export default function LocalidadesPage() {
  const user = useSelector((store) => store.authentication?.user, shallowEqual)
  const [data, setData] = useState([])
  const [localidadId, setLocalidadId] = useState(null)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [filteredData, setFilteredData] = useState([]);
	const [comunidades, setComunidades] = useState([])
  const [provincias, setProvincias] = useState([])
  const [filterOptions, setFilterOptions] = useState(initialFilters)
	const [collapsed, setCollapsed] = useState(true)
  const [refresh, setRefresh] = useState(false)
  const history = useHistory()

  function buttonFormatter(cell) {
    let deleteButton
    if (user?.role === 'admin')
      deleteButton = <Tooltip title="Eliminar">
                      <Button
                        style={buttonsStyle} size="small"
                        onClick={() => {
                          setLocalidadId(cell)
                          setOpenConfirmDialog(true)
                        }}>
                        <DeleteIcon/>
                      </Button>
                    </Tooltip>
    return (<>
      <Tooltip title="Editar">
        <Button
          style={buttonsStyle} size="small"
          onClick={() => history.push('/edit-localidad/' + cell)}>
          <EditIcon/>
        </Button>
      </Tooltip>
      {deleteButton}
    </>)
  }


  const columns = [
    { dataField: 'name', text: 'Localidad', sort: true },
    { dataField: 'provincia', text: 'Provincia', sort: true },
    { dataField: 'comunidad', text: 'Comunidad', sort: true },
    { dataField: 'createdAt', text: 'Fecha de creación', formatter: dateFormatter, sort: true },
    { dataField: 'id', text: '', formatter: buttonFormatter, searchable: false }
  ]

  useEffect(() => {
    getProvincias().then((resp) => {
      if (resp.status === 200) {
        setProvincias(resp.data)
        setRefresh(false)
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'No se han podido obtener las provincias.' })
    })
    getComunidades().then((respcust) => {
      if (respcust.status === 200) {
        setComunidades(respcust.data)
        setRefresh(false)
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'No se han podido obtener las comunidades.' })
    })
    getLocalidades().then((res) => {
      if (res.status === 200) {
        setData(res.data)
        setFilteredData(res.data)
        setRefresh(false)
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'No se han podido obtener las localidades.' })
    })
  }, [refresh])

  const handleSearch = async () => {
		if (!data.length) return
		setFilteredData(data.filter(item => {
		  	let filter = true
        if (filterOptions.comunidad && filterOptions.comunidad.length) filter = filter && filterOptions.comunidad.includes(provincias.find(c => c._id === item.provincia)?.comunidad)
        if (filterOptions.provincia && filterOptions.provincia.length) filter = filter && filterOptions.provincia.includes(item.provincia)
        if (filter) return item
        return false
		}))
	}

	const handleClearFilters = () => {
		setFilterOptions(initialFilters)
		setRefresh(true)
	}

	const handleChange = (element) => (event) => {
		setFilterOptions({ ...filterOptions, [element]: event.target.value });
	}; 

	const renderFiltersContent = () => {
		return <>
			<br/>
			<FormControl style={{ width: '100%' }}>
				<InputLabel id="demo-simple-select-standard-label">Comunidad</InputLabel>
				<Select
					labelId="demo-simple-select-standard-label"
					id="demo-simple-select-standard"
					value={filterOptions.comunidad || ''}
          multiple
					onChange={handleChange('comunidad')}
					MenuProps={MenuProps}
				>
				{comunidades?.map((option) => (
					<MenuItem key={option._id} value={option._id}>{option.name}</MenuItem>
				))}
				</Select>
			</FormControl>
			<br/>
      <br/>
      <FormControl style={{ width: '100%' }}>
				<InputLabel id="demo-simple-select-standard-label">Província</InputLabel>
				<Select
					labelId="demo-simple-select-standard-label"
					id="demo-simple-select-standard"
					value={filterOptions.provincia || ''}
          multiple
					onChange={handleChange('provincia')}
					MenuProps={MenuProps}
				>
				{provincias?.map((option) => (
					<MenuItem key={option._id} value={option._id}>{option.fullName}</MenuItem>
				))}
				</Select>
			</FormControl>
			<br/>
      <br/>
		</>
	}


  return (
    <>
      <Card>
        <CardHeader title='Lista de Localidades'>
          <CardHeaderToolbar>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => history.push('/edit-localidad')}
              >
                Añadir nueva
              </button>
            </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <FiltersCard 
						filtersContent={renderFiltersContent}
						collapsed={collapsed} 
						setCollapsed={setCollapsed}
						handleClearFilters={handleClearFilters}
						handleSearch={handleSearch}
					/>

          <Table
            data={getData(filteredData, provincias, comunidades)}
            columns={columns}
          />

          <ConfirmDialog
            title={'¿Estás seguro de que quieres eliminar esta localidad?'}
            open={openConfirmDialog}
            setOpen={setOpenConfirmDialog}
            onConfirm={() => {
              deleteLocalidad(localidadId).then(res => {
                if (res.status === 204) {
                  alertSuccess({  title: '¡Eliminada!', customMessage: 'Localidad eliminada correctamente.' })
                  setRefresh(true)
                }
              }).catch(error => {
                alertError({ error: error, customMessage: 'No se ha podido eliminar la localidad.' })
              })
            }}
          />
        </CardBody>
      </Card>
    </>
  );
}
